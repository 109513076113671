.tracker-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    min-width: 15vw;

    padding: 2rem;
    background: #fff;
    color: #0e0e0e;
    box-shadow: 0 6px 12px 0 rgba(0,0,0,1);

    border: 1px solid white;
    border-radius: 3rem;

    line-height: 1.5;
}

.heading {
    font-size: 3rem;
    text-align: left;
    text-transform: uppercase;
}

.card-text {
    font-size: 2rem;
}

.buttonPanel {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.buttonStyle {
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5;
    padding: 6px 12px;
    margin: 0 5px 0 5px;

    text-align: center;
    background-color: transparent;
    color: #0d6efd;

    border: 1px solid #0d6efd;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.buttonStyle:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.win-ratio {
    font-size: .95rem;
}


