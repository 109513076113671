@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body {
    margin: 0;
    font-family: 'Roboto', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Roboto',
    monospace;
}


.container {
    display: flex;
    width: 100%;
    min-height: 100vh;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #0f172f;
    color: white;
}

.settings {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    align-items: flex-end;
    position: absolute;
    top: 10px;
    right: 10px;
}

.tracker-container {
    display:flex;
    flex-direction: row;
}

.tracker-container > * {
    margin: 0 75px 0 75px;
}
