.settings:hover .dropdown {
    display: flex;
}

.dropdown {
    display: none;
    flex-direction: column;
    color: black;
    background: #f8f8f8;

}

.dropdown > a {
    padding: .25rem;
    cursor: pointer;
    font-family: "Roboto", serif;
    font-size: 1.25rem;
    line-height: 2rem;
    min-height: 2rem;
    text-decoration: none;

    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.settings > dialog {

    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    background: white;
    border-radius: .25rem;
}

dialog::backdrop {
    background: rgba(0, 0, 0, 0.5);
}

dialog > * {
    margin: .25rem;
}

dialog > button {
    align-self: flex-end;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
